import { FC, Children, ReactElement } from "react";
import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";
import classnames from "classnames";

interface IActiveLinkProps {
    children: ReactElement;
    activeClassName: string;
    includes?: string;
    forceActive?: boolean;
}

const ActiveLink: FC<IActiveLinkProps & LinkProps & { className?: string }> = ({
    children,
    activeClassName,
    forceActive,
    includes,
    href,
    as,
    className,
    ...props
}) => {
    const { asPath } = useRouter();
    const child = Children.only(children);

    const isActive =
        forceActive ||
        (!!includes && asPath.includes(includes)) ||
        asPath === as ||
        asPath === (typeof href === "string" ? href : href.pathname);

    return (
        <Link
            href={href}
            className={classnames(className, isActive && ["activeLink", activeClassName])}
            {...props}>
            {child}
        </Link>
    );
};

export default ActiveLink;
